.learnmorefinalbox{
  margin-top: 80px;
}

.hpdiscover{
  margin-top:80px
}

.iframecontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 94%;
  height: 100%;
}

.hphr{
  color:#FFF;
  margin-top:60px;
   margin-bottom:60px
}

  .PostTitle{
      color:#000;
      text-decoration: none;
  }

  .forartistehp{
    color:#fff;
    text-align:center
  }

  .calculatormaintitle{
    text-align:center;
    padding-bottom: 60px;
    padding-top:60px
  }
  

  .firstBox{
      margin-top:150px;
      margin-bottom:110px
  }


  .learnmoreimage{
    border-radius: 20px;
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    margin-top:100px
  }

  .dashboardlastimage{
    margin-top:120px;
      border-radius: 20px;
  }

  .discoverimage{
    border-radius: 20px;
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    margin-top:40px
  }

  .learnmoreimageunlimited{
    margin-top:80px;
    border-radius: 20px;
  }  

  .growaudienceimage{
    margin-top:70px;
    border-radius: 20px;
  }  


  .learnmorestreamimage{
    border-radius: 20px;
  }

  .learnmorestreamimage2{
    border-radius: 20px;
    margin-top:60px
  }


  .thirdBox{
    margin-top:150px;
    margin-bottom:110px
}

.fourthBox{
    margin-top: 250px;
    margin-bottom:250px
}

  .description{
      margin-top:20px;

  }

.stagenamehpinput{
  display:none
}

.playstorebutton{
    margin-left:30px
}

.footerlogo{
    height:99px;
    width:130px
}

.f1{
    font-size:20px
}

.f2{
    font-size:20px;
    padding-left:20px
}

.f3{
    font-size:20px;
    padding-left:20px
}

.signinnotify{
    padding-top:20px;
    padding-bottom:40px;
}



.forgotpassword{
  padding-top:0px;
  color:#000
}

.theresmore{
  margin-top:30px
}



.createbutton{
  font-size:18px
}



.deducted{
  /* padding-top:130px; */
  color:#fff;
  font-size:25px;

}

 .faqfirstBox{
     margin-top:150px;
 }

.musicratingbox{
  padding-top:70px
}

.launchdate{
  padding-top:60px;
  text-align:center;
  color:#C4C4C4;
 
font-size:18px
}



 /* LOGIN PAGE */

 #signinbody{
  background-image: url("/src/webapp/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  height:100vh
}
 .form-control{
  background-color:#1A1A1A;
  border-color:#B71B30;
  color:#C4C4C4;

}

::placeholder{
   color:#C4C4C4!important;
 }

  .form-control:focus{
  background-color:#1A1A1A;
  border-color:#B71B30;
  box-shadow:none;
  color:#FFF
}

   .fa-eye-slash:before {
     color:white;
     font-weight:bold;
   }

   .fa-eye:before {
    color:white;
}

.passwordformcontrol{
  border-right:none;
}


.input-group-addon{
  background-color:#1A1A1A;
  padding:13px 10px 10px 10px;
  border-top-right-radius:3px;
  border-bottom-right-radius:3px;
  border-right:1px solid #B71B30;
  border-top:1px solid #B71B30;
  border-bottom:1px solid #B71B30;
  cursor:pointer
  
 }


 .logintitle{
  text-align:center;
  padding-top:180px;
  color:#FFF
}

.loginnotify{
  padding-top:20px;
  padding-bottom:40px;
  text-align:center;
  color:#C4C4C4;
}



.loginbutton{
  font-size:20px;
  background-color: #D90C5F;
  border-radius: 25px;
  border-color:#D90C5F;
  padding-top:12px;
  padding-bottom:12px
}

.confirmationbutton{
  font-size:18px;
  background-color: #D90C5F;
  border-radius: 7px;
  border-color:#D90C5F;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:20px;
  padding-right:20px
}

.loginbutton:hover{
  background-color: #D90C5F;
  border-color:#D90C5F;
}

.loginbutton:focus{
  background-color: #D90C5F;
  border-color:#D90C5F;
  box-shadow:none;
}


.confirmationbutton:hover{
  background-color: #FA2D48;
  border-color:#FA2D48;
}

.confirmationbutton:focus{
  background-color: #FA2D48;
  border-color:#FA2D48;
  box-shadow:none;
}



.loginnoaccountnotify{
  padding-top:50px;
  color:#C4C4C4;
  text-align:center
}

.loginmakebold{
  font-weight:bold;
  color:#D90C5F
}

.forgotpassword{
  padding-top:0px;
  color:#FFF;
  font-weight:bold;
  text-align:center;
  font-size:13px
}

.loginerrornotify{
  color:#FFF;
  font-weight:bold;
  text-align:center;
  padding-top:20px;
}

.loginerrornotify2{
  color:#FFF;
  font-weight:bold;
  /* text-align:center; */
  padding-top:20px;
}

.copyrighttext{
  font-size: 14px;
  color:#FFF;
  text-align:center
}





 /* LOGIN PAGE */


/* REGISTER PAGE */
#registerbody{
  background-image: url("/src/webapp/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  /* height:100vh */
}

.signuptitle{
  text-align:center;
  padding-top:100px;
  color:#FFF
}

.signupnotify{
  padding-top:20px;
  padding-bottom:40px;
  text-align:center;
  color:#C4C4C4;
}

.signupusertype{
  margin-top:20px
}


/* REGISTER PAGE */



/* RESET PASSWORD PAGE*/
#resetbody{
  background-image: url("/src/webapp/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  height:100vh
}
/* RESET PASSWORD PAGE*/



/* EMAIL VERIFICATION PAGE*/
#emailverificationbody{
  background-image: url("/src/webapp/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  height:100vh
}


.evfirstbox{
  margin-top:100px
}
.evsecondbox{
  padding-top:0px
}

lottie-player {
  margin: 0 auto;
}

 .evtitle{
  text-align:center;
  padding-top:0px;
  color:#FFF;
  font-size:26px
}

.evsubtitle{
  text-align:center;
  padding-top:0px;
  color:#FFF;
  font-size:18px
}


@media screen and (max-width: 833px) {
  .evfirstbox{
    margin-top:40px
  }
}


/* EMAIL VERIFICATION PAGE*/



/* NAVIGATION BAR */
.navbar{
  background-color:#0A0A0A
}

.navmenu{
  margin-right:30px;
  text-decoration: none!important;

 }

 .linkmenu{
   padding-top:21px;
   margin-right:70px;
 }

 .linkmenu:hover{
   color:#FFF!important
 }

 .navbar-light .navbar-nav .nav-link {
   color:#FFF
 }

 .navbar-light .navbar-nav .nav-link:hover {
  color:#FFF
}

 .loginmenu{
   background-color:#0A0A0A!important;
   border-top-left-radius: 1px solid #FFF;
   padding: 8px 40px 8px 40px;
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
   border-bottom-left-radius: 25px;
   border-bottom-right-radius: 25px;
   border-color: #FFF!important;
 }

 .loginmenu:hover{
  background-color:#0A0A0A;
  border-color:#FFF;
}

 .createmenu{
   background-color:#D90C5F!important;
   border-color: #D90C5F!important;
   padding: 8px 23px 8px 23px;
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
   border-bottom-left-radius: 25px;
   border-bottom-right-radius: 25px;
 }

 .createmenu:hover{
  background-color:#D90C5F;
  border-color: #D90C5F!important;
}


 .headerlogo{
  width:120px;
  height:51px;
}

.logotext{
color:#FFF;

}


@media screen and (max-width: 833px) {
.navbar-toggler {
  background-color:#FFF
}

.headerlogo{
  width:80px;
  height:34px;
}

.logotext{
  font-size:16px
}
}

/* NAVIGATION BAR */



/* ABOUT PAGE */

#aboutpage{
  background-color: #121212;
  height:auto;

}

.aboutpagefirstbox{


  background-image: url("/src/webapp/aboutfirstbox.png");
  background-repeat: no-repeat;
  background-size: contain; 
}

.aboutfirstboximg{
  margin-top:30px;
}

.aboutfirstboximg2{
  margin-top:0px;
  background-color:#1E2226;
  padding-bottom:110px
}

.aboutctabutton{
  font-size:17px;
  background-color: #FA2D48;
  border-radius: 25px;
  border-color:#FA2D48;
  margin-top:15px;
  padding: 12px 23px 12px 23px;
  margin-left: -13px;
}

.aboutctabutton:hover{

  background-color: #FA2D48;
  border-color:#FA2D48;

}

.abouttitle{
    color:#FFF;
    font: size 55px;
    font-weight:bold;
    padding-top:140px
}

.abouttitle2{
  color:#FFF;
  font: size 55px;
  font-weight:bold;
  padding-top:200px;
  text-align:center;
  padding-bottom:30px
}

.abouttitle2hp{
  color:#FFF;
  font: size 55px;
  font-weight:bold;
  padding-top:0px;
  text-align:center;
  padding-bottom:30px
}

.abouttitle4{
  color:#FFF;
  font: size 55px;
  font-weight:bold;
  padding-top:280px;
  /* text-align:center; */
  /* padding-bottom:30px */
}

.aboutwhatweoffer{
  text-align:center;
  margin-top:20px;
  color:#FFF;
  font-size:22px;
  padding-top:45px;
  padding-bottom:15px
}

.abouttitle3{
  color:#FFF;
  font: size 55px;
  font-weight:bold;
  padding-top:140px;
  text-align:center
}

.abouttitle6{
  color:#FFF;
  font: size 55px;
  font-weight:bold;
  padding-top:40px;
  text-align:center
}

.aboutdescription{
  margin-top:20px;
  color:#C4C4C4;
  font-size:18px;
  text-align:justify;

}

.aboutdescription2{
  margin-top:0px;
  color:#C4C4C4;
  font-size:18px;
  text-align:justify;

}

.afbimg{
padding-top:70px
  /* width:650px;
  height:594px; */
}

.aboutpagesecondbox{

  padding-bottom:100px;
  background-image: url("/src/webapp/aboutfirstbox.png");
  background-repeat: no-repeat;
  background-size: contain; 
}

.aboutinnerbox1{
  background-color:#000;
  padding-top:40px;
  padding-bottom:60px;
  margin-top:30px
}

.aboutinnerbox2{
  background-color:#000;
  padding-top:40px;
  margin-top:30px;
  margin-left:-55px
}

.aboutinnerbox3{
  background-color:#000;
  padding-top:40px;
  margin-top:30px;
  padding-bottom:60px;
}

.aboutinnerbox4{
  background-color:#000;
  padding-top:40px;
  margin-top:30px;
  margin-left:-55px
}

.aboutstats1{
  margin-top:60px;
}

.aib1{
  color: #3599A8;
  font-weight:bold;
  padding-top:9px;
  padding-left:12px
}

.aib2{
  color: #4BD887;
  font-weight:bold;
  font-size:50px;
  padding-top:20px;
  padding-left:12px
}

.aib3{
  color: #C4C4C4;
  padding-top:15px;
  padding-left:12px
}

.aboutpagethirdbox{
padding-bottom:200px
}

.faqlastpage{

    padding-bottom:200px

}

@media screen and (max-width: 833px) {
  .faqlastpage{

    padding-bottom:100px

}


.hphr{
  color:#FFF;
  margin-top:60px;
   margin-bottom:20px
}

.deducted{

  color:#fff;
  font-size:20px;
  padding-top:50px
}
  .aboutdescription{
    font-size:15px;
  }

  .aboutdescription2{
    font-size:15px;
  }

  .aboutctabutton{
    font-size:12px;
  }


  .abouttitle{
    padding-top:80px;
}

.aboutfirstboximg2{
  background-color:#121212!important;

 
    padding-bottom:10px
  
}

.afbimg{
  padding-top:0px
  /* width:350px;
  height:320px; */
}

.abouttitle2{
  padding-top:70px;
}

.abouttitle2hp{
  padding-top:70px;
}

.abouttitle3{
  padding-top:70px;
}

.abouttitle6{
  padding-top:70px;
}

.abouttitle4{
  padding-top:0px;
}

.aboutfirstboximg2{
  margin-top:30px;
  background-color:#1E2226;
  padding-bottom:110px
}

.aboutinnerbox1{
  background-color:#000;
  padding-top:40px;
  padding-bottom:40px;
  margin-top:30px
}

.aboutinnerbox2{
  background-color:#000;
  padding-top:0px;
  margin-top:0px;
  margin-left:0px
}

.aboutinnerbox3{
  background-color:#000;
  padding-top:40px;
  margin-top:0px;
  padding-bottom:60px;
}

.aboutinnerbox4{
  background-color:#000;
  padding-top:0px;
  margin-top:0px;
  margin-left:0px;
  padding-bottom:40px
}

.aib1{
  text-align:center;

}

.aib2{
  text-align:center;
  font-size:30px;
  padding-top:0px;
}

.aib3{
  text-align:center;
  padding-top:0px;
}

.aboutfooterfirstbox{
  margin-top:0px!important;
  padding-bottom:50px;

}

.footermenuhead{
  text-align:center
}

.footermenubody{
  text-align:center
}

.footersocial{
  text-align:center
}

.footersocial2{
  text-align:center
}

.footer2{
  padding-top:20px
}

.footer3{
  padding-top:20px
}

    .mobilefooter p, h6, .footerlogop{
        text-align:center
    }

}


/* ABOUT PAGE */


/* FOOTER */
.footermenuhead{
  color:#8D8D8D;
  padding-top:50px

}

.footermenubody{
  color:#FFF
}

.aboutfooterfirstbox{
  /* margin-top:150px;
  padding-bottom:50px; */

}

#footerlastbox{
  background-color:#0A0A0A;
  /* margin-top:200px; */
  padding-top:80px;
  padding-bottom:40px

}



    .footersocial{
        margin-top:43px
    }

    .footersocial2{
      margin-top:43px
  }


    .footerlogo{
      width: 250px;
      height:141px
    }

/* FOOTER */

/* FAQ PAGE */

#faqpage{
  background-image: url("/src/webapp/faqbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  /* height:100vh */
}

.faqsubheading{
  color:#FFF;
  padding-left:18px;
  padding-bottom:20px
}

.faqtitle{
  text-align:center;
  color:#FFF;
}

.faqdescription{
  margin-top:20px;
  color:#C4C4C4;
  text-align:center

}

.accordion-button:not(.collapsed){
  color:#FFF;
  background-color:transparent;
}

.accordion-body{
  background-color:#121212;
  color:#C4C4C4
}

/* .accordion-item:last-of-type .accordion-button.collapsed {
  background-color:#121212;
  color:#FFF
} */

.accordion-flush .accordion-item:first-child {
  border-bottom:0
}



.accordion-flush .accordion-item {
  border-bottom:0
}


.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("/src/webapp/arrowdown.png");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("/src/webapp/uparrow.png");
    transform: rotate(
-180deg);
}

.accordion-flush .accordion-item .accordion-button {
  background-color:#121212;
  color:#FFF;
  border-bottom: none
}

.faqaddspacing{
  margin-top:30px
}



/* FAQ PAGE */


/* HOMEPAGE */
.homepagefavourite{
  color:#FF0075
}

.hpyt{
  width: 520px;
   height:450px ;
   border-radius:15px
}

.homepagefirstbox{
  padding-top:40px;
  padding-bottom:120px;

  background-image: url("/src/webapp/homepage1.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  /* height:100vh */
}

.homepagefirstboxlearnmore{
  padding-top:40px;
  padding-bottom:120px;

  background-image: url("/src/webapp/homepage1.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  /* height:100vh */
}

.homepagetitle{
  color:#fff;
  font-size:36px;
  padding-top:180px
}

.homepagetitle4{
  color:#fff;
  font-size:36px;
  padding-top:100px
}


.homepagetitle5{
  color:#fff;
  font-size:25px;
  padding-top:40px
}

.homepagetitle52{
  color:#fff;
  font-size:25px;
  padding-top:40px
}

.homepagetitle51{
  color:#fff;
  font-size:25px;
  padding-top:0px
}


.homepagetitle6{
  color:#fff;
  font-size:20px;
  padding-top:40px
}

.homepagetitle7{
  color:#fff;
  font-size:20px;

}




.homepagetitle2{
  color:#fff;
  font-size:30px;
  text-align:center;
  padding-top:70px;
  padding-bottom:30px
}

.homepagetitle3{
  color:#fff;
  font-size:30px;
  text-align:center;
  padding-top:0px;
  padding-bottom:30px
}

.homepagedescription{
  color:#C4C4C4;
  padding-top:10px;
font-size:18px
}

.calculatorhead{
  color:#C4C4C4;
font-size:30px
}

.calculatorbody{
  color:#C4C4C4;
font-size:22px
}

.calculatorinput{
  height:70px;
  width:250px;
  font-size:27px
}


.homepagectabutton{
  font-size:17px;
  background-color: #D90C5F;
  border-radius: 25px;
  border-color:#FA2D48;
  margin-top:10px;
  padding: 12px 20px 12px 20px;

 
}

.homepagectabutton1{
  font-size:17px;
  background-color: #D90C5F;
  border-radius: 25px;
  border-color:#FA2D48;
  margin-top:10px;
  padding: 9px 35px 9px 35px;

}

.homepagectabutton2{
  font-size:17px;
  background-color: #D90C5F;
  border-radius: 25px;
  border-color:#FA2D48;
  margin-top:10px;
  padding: 9px 20px 9px 20px;

 
}

.homelink{
  padding-left:0rem!important
}

.homepagectabutton:hover{
  background-color: #D90C5F;
  border-color:#D90C5F;
}

.homepagectabutton1:hover{
  background-color: #D90C5F;
  border-color:#D90C5F;
}

.homepagectabutton2:hover{
  background-color: #D90C5F;
  border-color:#D90C5F;
}

.appillimage{
  width: 367px;
  height:500px
}


  .hpi1{
    background-image: url("/src/webapp/homepageimage.png");
    background-repeat: no-repeat;
    background-size: cover; 

  width:600px;
  height:561px
}

.homepageyt{
  margin-top:55px;
  margin-left:25px
}

.homepagesecondbox{
  /* padding-top:150px; */

  /* background-image: url("/src/webapp/homepage2.png"); */
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-bottom:160px;
  padding-top:90px;
  /* height:100vh; */
}

.homepagesecondboxlearnmore{
  /* padding-top:150px; */

  /* background-image: url("/src/webapp/homepage2.png"); */
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-bottom:90px;
  padding-top:60px;
  /* height:100vh; */
}

.homepagesecondboxhp{
  /* padding-top:150px; */

  /* background-image: url("/src/webapp/homepage2.png"); */
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-bottom:90px;
  padding-top:20px;
  /* height:100vh; */
}



.homepagethirdbox{
  background-color:#722452
}

.homepagefourthbox{
  background-image: url("/src/webapp/homepagelastbox.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-top:120px;
  padding-bottom:160px
}

.homepagehtsp{
  color:#F2F2F2;
  background-color:#151A20;
  padding-top:23px;
  padding-bottom:23px;
  border-radius:20px

  /* text-align:center */
}

.hppb{
  padding-left:20px
}

.homepagepagination{
  background-color:#1E2227;
  margin-left:20px;
  padding: 20px 20px 20px 20px;
  border-radius:50px;
  margin-top:20px;
  margin-bottom:20px
}

.homepagedownloadtitle{
  color:#FFF;
  
}

.homepagedownloadbox{
  padding-top:100px;
  padding-bottom:100px
  
}

.homepagedownloadbutton{
  margin-top:20px;
  background-color:#121212;
  color:#FFF;
  border:#121212;
  padding: 8px 23px 8px 23px;
}

.homepagedownloadbutton:hover{
  margin-top:20px;
  background-color:#121212;
  color:#FFF;
  border:#121212;
  padding: 8px 23px 8px 23px;
}

.homepagelasttitle{
  color:#FFF;
  font-size:45px
}

.navbar-light .navbar-toggler-icon {

  background-image: url("/src/webapp/hamburger.png");
  height:15px;
  width:21px
}

.navbar-light .navbar-toggler{
  background-color:#0A0A0A
}

@media screen and (max-width: 833px) {

  .hpdiscover{
    margin-top:90px
  }
    .signuptitle{
      text-align:center;
      padding-top:80px;
      color:#FFF
    }
  
    .evtitle{
      font-size:18px
    }
  
    #registerbody{
      background-image: url("/src/webapp/signinbg.png");
      background-repeat: no-repeat;
      background-size: cover; 
      background-color: #121212;
      height:130vh
    }
  
  


  .homepagedownloadtitle{
    font-size:25px
  }

.hpyt{
  width:308px;
  height:280px
}


.homepageyt{
  margin-top:24px;
  margin-left:10px
}

  .homepagefirstbox{
    padding-top:100px;
    padding-bottom:0px
  }

  .homepagefirstboxlearnmore{
    padding-top:0px;
    padding-bottom:0px
  }
  .homepagetitle{
    color:#fff;
    font-size:25px;
    padding-top:0px
  }

  .homepagetitle4{
    color:#fff;
    font-size:25px;
    padding-top:50px
  }

  .homepagetitle5{
    color:#fff;
    font-size:25px;
    padding-top:50px
  }

  .homepagetitle52{
    color:#fff;
    font-size:25px;
    padding-top:0px
  }


  .homepagetitle6{
    color:#fff;
    font-size:20px;
    padding-top:30px
  }

  .homepagetitle7{
    color:#fff;
    font-size:20px;
    padding-top:0px
  }

  .homepagectabutton{
    font-size:14px;
    background-color: #D90C5F;
    border-radius: 25px;
    border-color:#D90C5F;
    margin-top:5px;
    padding: 12px 23px 12px 23px;
    margin-bottom:60px;
    margin-left:-13px
   
  }

  .homepagectabutton1{
    font-size:14px;
    background-color: #D90C5F;
    border-radius: 25px;
    border-color:#D90C5F;
    margin-top:5px;
    padding: 12px 23px 12px 23px;
    margin-bottom:60px;
    margin-left:-13px
   
  }

  .homepagectabutton2{
    font-size:14px;
    background-color: #D90C5F;
    border-radius: 25px;
    border-color:#D90C5F;
    margin-top:5px;
    padding: 12px 23px 12px 23px;
    margin-bottom:60px;
    margin-left:-13px
   
  }

  .hpi1{
    background-image: url("/src/webapp/homepageimage.png");
    background-repeat: no-repeat;
    background-size: cover; 
    width:350px;
    height:327px
  }

  .homepagetitle2{
  color:#fff;
  font-size:22px;
  text-align:center;
  padding-top:60px;
  padding-bottom:30px
}

.homepagetitle3{
  color:#fff;
  font-size:22px;
  text-align:center;
  padding-top:60px;
  padding-bottom:30px
}
.homepagesecondbox{
  /* padding-top:150px; */

  background-image: none;
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-bottom:130px;
  padding-top:0px;
  /* height:100vh; */
}

.homepagesecondboxlearnmore{
  /* padding-top:150px; */

  background-image: none;
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  padding-bottom:0px;
  padding-top:0px;
  /* height:100vh; */
}


.homepagelasttitle{
  color:#FFF;
  font-size:25px
}

.homepagefourthbox{

  padding-top:80px;
  padding-bottom:10px
}
}

 /* #signinbody{
  background-image: url("/src/webapp/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-color: #121212;
  height:100vh
} */
/* HOMEPAGE */









/* .activeFaqMenu{
  background-color:#A9A9A9;
  padding-top:15px;
  padding-bottom:15px
} */

.faqmenu1{
  text-align:center;
  margin-top:20px;
  border-radius: 16px;
  cursor:pointer;
  color: #FFF;
  padding-top:15px;
  padding-bottom:15px;
  background-color:#14497D;
}

.faqmenu{
  text-align:center;
  margin-top:40px;
  border-radius: 16px;
  cursor:pointer;
  color: #707070
}



body{
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
}









/* REACT ROUTER FADE */

body {
  margin: 0;
  padding: 0;

}
.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 80px;
}

/* DEMO STYLES */
.nav {
  padding: 30px 0;
  background-color: #222;
}
.nav a {
  display: inline-block;
  width: 100px;
  text-align: center;
  margin: 0 15px;
  padding: 10px;
  border: 1px rgba(255,255,255,0.5) solid;
  transition: all 0.3s linear;
  text-decoration: none;
  color: white;
}
.nav a.active {
  background-color: #61DAFB;
  color: #222;
}
section {
  padding: 50px 0;
  max-width: 600px;
  margin: 0 auto;
}






/* .page {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
} */

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}
@media screen and (max-width: 833px) {

  .dashboardlastimage{
    margin-top:0px;
      border-radius: 20px;
  }

.calculatormaintitle{
  text-align:center;
  padding-bottom: 30px;
  padding-top:30px
}


.calculatorhead{
  color:#C4C4C4;
font-size:20px;
margin-top:20px
}


.calculatorinput{
  height:45px;
  width:250px;
  font-size:27px
}



  .hpdiscover{
    margin-top:0px;
  }


  .homepagesecondboxhp{
    /* padding-top:150px; */
  
    /* background-image: url("/src/webapp/homepage2.png"); */
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: #121212;
    padding-bottom:50px;
    padding-top:20px;
    /* height:100vh; */
  }
  
  
  .forartistehp{
    color:#fff;
    text-align:center;
    margin-top:50px
  }

  .growaudienceimage{
    margin-top:0px;
    border-radius: 20px;
  }  

  

  #footerlastbox{
  background-color:#0A0A0A;
  margin-top:0px;
  padding-top:0px;
  padding-bottom:40px

}

.learnmorefinalbox{
  margin-top: 40px;
}


.learnmoreimageunlimited{
  margin-top:40px;
  border-radius: 20px;
} 
.learnmoreimage{

  /* border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  margin-top:0px
}
.musicratingbox{
  padding-top:70px
}

.centermobile{
  text-align:center
}



.confirmationbutton{
  font-size:16px;
  background-color: #FA2D48;
  border-radius: 14px;
  border-color:#FA2D48;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:20px;
  padding-right:20px
}



  .logintitle{
    text-align:center;
    padding-top:90px;
    color:#FFF
  }

  .loginbutton{
    font-size:18px;
    background-color: #D90C5F;
    border-radius: 25px;
    border-color:#D90C5F;
    padding-top:8px;
    padding-bottom:8px
  }

  .copyrighttext{
    font-size: 9px;
    color:#fff;
    text-align:center
  }
}



/* MOBILE RESPONSIVENESS
@media screen and (max-width: 833px) {
  .navmenucreate{
    margin-bottom:130px!important
  }
    .mobilefooter p, h6, .footerlogop{
        text-align:center
    }

    .footerlogo{
        height:150px;
        width:197px;
        margin-bottom:30px
    }

     .footer3{
        margin-top:30px
    }

    .footer2{
        margin-top:30px
    }

    .footersocial{
        margin-top:20px
    }

    .ctaheaderbuttons{
        float:left
    }

    .navmenudesktop{
        display: none;
    }

    /* .page {
        position: relative;
        top: 50px;
        left: 0;
        right: 0;
      } */
/* } */









/* advanced - enter transition 800ms, exit 150ms */
/* .fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    transform: translateY(-10px);
}
.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
}
.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 150ms linear, transform 150ms ease-in;
} */
