.homebody{
    background-image: url("../webapp/collagebg2.png");
    /* background-repeat: no-repeat; */
    background-size: cover;
    background-position: center;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    height:100vh
}

span.arrow-left,span.arrow-right{
  display: block;
  background-color: #555;
  top: 40px;
  color:white;
  cursor: pointer;
}

.featuretitle1{
    color:#C4C4C4!important;
    font-size:18px;
  }

  .featuretitle2{
    color:#FFFFFFF2!important;
    font-size:18px;
font-weight:light;
margin-top:-15px;

  }

  .featuretitle3{
    color:#D8D8D8!important;
    font-size:18px;
    font-weight:lighter;
    margin-top:-15px;
  }

  .featuremoodcard-block {
    height: 300px;
    width: 500px;

    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}

.featuremoodnote{
    color:#fff;
    padding-right:70px;
    padding-left:20px;
    padding-top:200px;

}

  .feature-1 {
    background-image: url(../webapp/aoft.jpg);
    cursor:pointer;
}

.feature-1:hover{
    opacity: 0.7;
  }

.feature-2 {
    background-image: url(../webapp/omahlay.jpg);
    cursor:pointer;
}

.feature-2:hover{
    opacity: 0.7;
  }

.feature-3 {
    background-image: url(../webapp/top100bigbanner.png);
    cursor:pointer;
}

.feature-3:hover{
    opacity: 0.7;
  }

.feature-4 {
    background-image: url(../webapp/davido.jpg);
    cursor:pointer;
}

.feature-4:hover{
    opacity: 0.7;
  }

.feature-5 {
    background-image: url(../webapp/reekado.jpg);
}

.feature-5:hover{
    opacity: 0.7;
  }

.feature-6 {
    background-image: url(../webapp/lax.jpg);
}

.feature-6:hover{
    opacity: 0.7;
  }

.feature-7 {
    background-image: url(../webapp/wizkid.jpg);
}

.feature-7:hover{
    opacity: 0.7;
  }

.feature-8 {
    background-image: url(../webapp/wizkid.jpg);
}

.feature-8:hover{
    opacity: 0.7;
  }

.feature-9 {
    background-image: url(../webapp/wizkid.jpg);
}

.feature-9:hover{
    opacity: 0.7;
  }

.feature-10 {
    background-image: url(../webapp/wizkid.jpg);
}

.feature-10:hover{
    opacity: 0.7;
  }



  .feature-1:hover .middle {
    opacity: 1;
  }

  .feature-2:hover .middle {
    opacity: 1;
  }

  .feature-3:hover .middle {
    opacity: 1;
  }

  .feature-4:hover .middle {
    opacity: 1;
  }



  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .middle2 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .middlethree{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }


  .playicon1{
    font-size:60px;
    color:white
}

.playicon2{
    font-size:60px;
    color:white
}

.playiconthree{
    font-size:60px;
    color:white
}

.hottrackscrolltwo{
    margin-top:-20px!important;
}





.hottrackmoodcard-block {
    height: 200px;
    width: 200px;

    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}
/* 
.albumimage{
  height: 200px;
  width: 200px;

  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 24px;
} */

.toptenmoodcard-block {
    height: 200px;
    width: 200px;

    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}

.hottrack-1 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-1:hover{
    opacity: 0.7;
  }

  .hottrack-2 {
    background-image: url(../webapp/gucci.jpg);
}

.hottrack-2:hover{
    opacity: 0.7;
  }

  /* .hottrack-3 {
    background-image: url(/public/images/webapp/paris.jpg);
} */

.hottrack-3:hover{
    opacity: 0.7;
  }

  /* .hottrack-4 {
    background-image: url(/public/images/webapp/talvin.jpg);
} */

.hottrack-4:hover{
    opacity: 0.7;
  }

  .hottrack-5 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-5:hover{
    opacity: 0.7;
  }

  .hottrack-6 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-6:hover{
    opacity: 0.7;
  }

  .hottrack-7 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-7:hover{
    opacity: 0.7;
  }

  .hottrack-8 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-8:hover{
    opacity: 0.7;
  }

  .hottrack-9 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-9:hover{
    opacity: 0.7;
  }

  .hottrack-10 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-10:hover{
    opacity: 0.7;
  }


.topten-1 {
    background-image: url(../webapp/top10.jpg);
}

.hottrack-1:hover{
    opacity: 0.7;
  }

  .hottrack-2 {
    background-image: url(../webapp/gucci.jpg);
}

.hottrack-2:hover{
    opacity: 0.7;
  }

  /* .hottrack-3 {
    background-image: url(/public/images/webapp/paris.jpg);
} */

.hottrack-3:hover{
    opacity: 0.7;
  }

  .dashprofilepicture{
    height:30px;
    width:30px
  }

  /* .hottrack-4 {
    background-image: url(/public/images/webapp/talvin.jpg);
} */

.hottrack-4:hover{
    opacity: 0.7;
  }

  .hottrack-5 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-5:hover{
    opacity: 0.7;
  }

  .hottrack-6 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-6:hover{
    opacity: 0.7;
  }

  .hottrack-7 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-7:hover{
    opacity: 0.7;
  }

  .hottrack-8 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-8:hover{
    opacity: 0.7;
  }

  .hottrack-9 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-9:hover{
    opacity: 0.7;
  }

  .hottrack-10 {
    background-image: url(../webapp/howard.jpg);
}

.hottrack-10:hover{
    opacity: 0.7;
  }

  .viewall{
      float:right;
      padding-right:10px
  }

  .endbox1{
      padding-top:30px;
      padding-bottom:30px;
      padding-left:30px;
      padding-right:30px;
      background-color:#0000004D;
      border-radius:20px;
  }

  .endbox2{
    padding-top:30px;
    padding-bottom:30px;
    padding-left:30px;
    padding-right:30px;
    background-color:#0000004D;
    border-radius:20px;

}

.endbox3{
    padding-top:30px;
    padding-bottom:30px;
    padding-left:30px;
    padding-right:30px;
    background-color:#0000004D;
    border-radius:20px;

}

.endboxtext{
    color:#FA2D48!important;
    text-align:center;
}

.endboxrow{
    margin-top:40px;
    margin-bottom:80px;

}

.footer{
    padding-top:20px;
    padding-bottom:20px;
    background-color:#323232;

}

.footer p{
    color:#EBEBF599;
    font-size:11px;
}

.newmusic a{
  text-decoration:none;
  color:#EBEBF599;
}

.newmusic a{
  color:#EBEBF599;
}

.nmcont .col-3{
margin-top:20px!important;
}

.hotmusic a{
  text-decoration:none;
  color:#EBEBF599;
}

.hotmusic a{
  color:#EBEBF599;
}

.hottracks a{
  text-decoration:none;
  color:#EBEBF599;
}

.hottracks a{
  color:#EBEBF599;
}

.cont{
  color:white;
}


.flex-container {
  display: flex;
}

.musicbox{
  margin-right:20px;
}

.desktopdisplay{
  display:show
}

.mobiledisplay{
  display:none;
}



@media screen and (max-width: 833px) {
 

  div.musicitem{
    width: 50%;
    /* height: 50px; */
    /* background: red; */
    margin-left: 30px;
    /* margin: 20px 20px 30px -15px; */
    float: left;
    }

    div.musicitemhome{
      width: 50%;
      /* height: 50px; */
      /* background: red; */
      margin-left: -20px;
      /* margin: 20px 20px 30px -15px; */
      float: left;
      }

    div.musicitemmain{
      width: 40%;
      /* height: 50px; */
      /* background: red; */
      margin-left: 20px;
      /* margin: 20px 20px 30px -15px; */
      float: left;
      }
  
      .musicimagemain{
        height: 140px;
        width: 140px;
      
        background-color: #fff;
        border: none;
        background-position: center;
        background-size: cover;
        /* transition: all 0.2s ease-in-out !important; */
        border-radius: 15px;
      }


.musicimage{
  height: 150px;
  width: 150px;

  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  /* transition: all 0.2s ease-in-out !important; */
  border-radius: 15px;
}

    .textfont{
      font-size:12px;
      text-align:center
    }

    .textfontnewmusic{
      font-size:12px;
      text-align:center
    }


  .featuremoodcard-block {
    height: 300px;
    width: 300px;

    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}

.songtitle{
  text-align:center;
}

.imghottrack{
  text-align:center!important;

}

.artistnumber{
  display:none;
}

.songtitle{
  font-size:10px;
}

.endbox1{
  margin: 30px 20px 0px 20px;
}

.endbox2{
  margin: 30px 20px 0px 20px;
}

.endbox3{
  margin: 30px 20px 0px 20px;
}

.albumimagep{
  text-align:center!important;
}

/* .albumimage{
  height: 150px;
  width: 150px;

  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 24px;
} */

.morebyimg{
  height: 160px!important;
  width: 160px!important;
}

.floatleft{
  float:right;
}

.contbox{
  display: inline-block;

}
.cont{
  color:white;
}


.flex-container {
  display: flex;
}

.desktopdisplay{
  display:none
}

.mobiledisplay{
  display:show;
}

.menuheading{
  padding-bottom:10px;
}

.qdet1, .qdet2, .qdet3{
  text-align:center
}

/* .moreby{
  margin-bottom:-40px!important;
} */

.nmsartistalbum, .nmsartistname, .nmsartistyear{
  text-align:center;
}

.newmusicsingleboximgp{
  text-align:center;
}

.newmusicsingletime{
font-size:12px!important;
}

.newmusicsingletitle{
  font-size:12px!important;
  }



}
