
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999;

}

.loading-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100px;
    line-height: 100px;

}
.loading-text span {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.loading-text span:nth-child(1) {
    filter: blur(0px);
    animation: blur-text 1.5s 0s infinite linear alternate;
}
.loading-text span:nth-child(2) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.2s infinite linear alternate;
}
.loading-text span:nth-child(3) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.4s infinite linear alternate;
}
.loading-text span:nth-child(4) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.6s infinite linear alternate;
}
.loading-text span:nth-child(5) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.8s infinite linear alternate;
}
.loading-text span:nth-child(6) {
    filter: blur(0px);
    animation: blur-text 1.5s 1s infinite linear alternate;
}
.loading-text span:nth-child(7) {
    filter: blur(0px);
    animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(7) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(8) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(9) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(10) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(11) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(12) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(13) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
@keyframes blur-text {
    0% {
        filter: blur(0px);
   }
    100% {
        filter: blur(4px);
   }
}




.scrolling-wrapper {
    overflow-x: auto;
    /* overflow-x: hidden; */
}



.profilepicture{
  text-align:center;
  padding-top:20px;
}

.profilename{
  text-align:center;
  color:#fa2d48;
}



/* .active{
  color: #fa2d48!important;
} */

.sitetitle{
  text-align:center;
}

.makewhite{
  color:#fff
}

.makered{
  color: #fa2d48;
}

.uploadicon{
  color:#fff;
  font-size:200px;
}
#records_table{
  color:#fff
}

#newmusicbox{
  color:#fff
}
#singlebox{
  display:none;
}

#albumbox{
  display:none;
}

#tnos{
  color:#fff!important;
  /* font-size:20px; */
}

#tnost{
  color:#fff!important;
  /* font-size:20px; */
}

.forartistonly{
  display:none
}

.artistnotify{
  display:none
}

.logoutfunction{
  cursor:pointer
}

/* .btn{
  font-weight:bold;
} */

.makebold{
  font-weight:bold;
}

.adsummary{
  font-size:15px;
  padding-bottom:20px;
  padding-top:20px;

}

.albumimage{
  height: 160px;
  width: 160px;

  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 24px;
}

.centerthis{
  text-align:center!important
}



.settingsp{
  color:white;
}

.moodcard-block {
    height: 180px;
    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}
.moodcard-block:hover {
    transform: translateY(-5px);
    box-shadow: none;
}

.card-block {
    height: 90px;
    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out !important;
    border-radius: 24px;
}
.card-block:hover {
    transform: translateY(-5px);
    box-shadow: none;
}
.card-1 {
    background-color: #4158d0;
    background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
}
.card-2 {
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}
.card-3 {
    background-color: #00dbde;
    background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);
}
.card-4 {
    background-color: #fbab7e;
    background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}
.card-5 {
    background-color: #85ffbd;
    background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
}
.card-6 {
    background-color: #fa8bff;
    background-image: linear-gradient(45deg, #fa8bff 0%, #2bd2ff 52%, #2bff88 90%);
}
.card-7 {
    background-color: #fa8bff;
    background-image: linear-gradient(45deg, #fa8bff 0%, #2bd2ff 52%, #2bff88 90%);
}
.card-8 {
    background-color: #fbda61;
    background-image: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
}
.card-9 {
    background-color: #4158d0;
    background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
}
.card-10 {
    background-color: #ff3cac;
    background-image: linear-gradient(225deg, #ff3cac 0%, #784ba0 50%, #2b86c5 100%);
}

.navlink{
  padding-left:20px;
  margin-top:40px;
  color:#fff;
  font-weight:bold;
  /* text-decoration: none; */
}

.navigationlink{
    text-decoration: none;
}

.navlink:hover{
  color:#EBEBF599!important;
}

.navlinkico{
padding-right:5px;
  color:#FA2D48!important;
}

.list-group a{
  text-decoration:none !important;
  color:#EBEBF599;

}



.bg-light{
    /* background-color:#121317!important;
  border-right:#121317!important;
  border-bottom:#121317!important;
  color:#D8D8D8!important; */
  /* padding-top:40px; */
  
  }


  .list-group-item{
        padding-top:40px;
  }

/* 
  .bg-light:hover{
background-color:red!important;} */
  
  .linkicon{
    margin-right:10px;
  }
  
  .menuheading{
    color:#D8D8D8!important;
    font-size: 16px;
    font-weight:bold;
  }
  
  .artistnumber{
    color:#D8D8D8!important;
    padding-top:10px;
    float:left;
  }

  .songtitle{
    color:#D8D8D8!important;
  }

  .toptitle{
      font-size:14px;
      padding-top:5px;
    color:#D8D8D8!important;
  }

  .artistname{
    color:#EBEBF599!important;
    font-size:14px;
  }

  .hottracksbox{
      background-color:#1A1B1E;
      padding-top:20px;
      margin-left:10px;
  }

  .moodtitle{
    color:#D8D8D8!important;
    text-align:center;
  }

  .pageheading{
    margin-left:70px;
    margin-top:60px
  }

  @media screen and (max-width: 833px) {  
    .pageheading{
      margin-left:0px;
      margin-bottom:40px
    }
  }

