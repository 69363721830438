/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  background-color:#121317!important;

}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;

}

#page-content-wrapper {
  min-width: 100vw;
  margin-left: 270px;
  margin-top:0px;
  transition: all 0.3s;
  background-color:#121212;
  background-image: url("/src/webapp/webappinner.png");
background-repeat: no-repeat;
background-size: contain; 
min-height:100vh;
}

#page-content-wrapper2 {
  min-width: 100vw;
  margin-left: 270px;
  margin-top:0px;
  transition: all 0.3s;
  background-color:#121212;
  background-image: url("/src/webapp/webappinner.png");
background-repeat: no-repeat;
background-size: contain; 
/* height:100vh; */
}

#webappsidebarCollapse{
  display:none
}


.explorepagenewmusic{
  padding-bottom:20px
}
.webappinnercontent{
  /* padding-left:20px */
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.searchsongart{
  width:250px;
   height:250px;
   border-radius: 24px
}



@media screen and (max-width: 833px) {  

  .searchsongart{
    width:150px;
     height:150px;
     border-radius: 24px
  }
  

  #page-content-wrapper{
    height:100%;
    /* margin-top:50px; */
  }

  #webappsidebarCollapse{
    display:block
  }

  #page-content-wrapper2{
    /* margin-top:50px; */
  }



  #sidebar {
    position: relative; 
  }

  #page-content-wrapper {
    min-width: 100vw;
    margin-left: 0px;
  }

  #page-content-wrapper2 {
    min-width: 100vw;
    margin-left: 0px;
  }

  #sidebar .custom-menu .btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative; 
    display:block
  }
  
}



@media (min-width: 768px) {

  
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
   
  }


  #page-content-wrapper2 {
    min-width: 0;
    width: 100%;
   
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
