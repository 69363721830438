/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

  

.musicimage{
    height: 200px;
    width: 200px;
  
    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    /* transition: all 0.2s ease-in-out !important; */
    border-radius: 15px;
  }

  .longtable{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .artsubmitbutton{
      display:none
  }

  .songsubmitbutton{
    display:none
}

.artandsongsubmitbutton{
    display:none
}
  
  
  .musicimagemain{
    height: 170px;
    width: 170px;
  
    background-color: #fff;
    border: none;
    background-position: center;
    background-size: cover;
    /* transition: all 0.2s ease-in-out !important; */
    border-radius: 15px;
  }
  div.musicitem{
    width: 12%;
    /* height: 50px; */
    /* background: red; */
    /* margin: 20px; */
    /* margin: 20px 20px 30px -15px; */
    float: left;

    }

    .editpicture{
        display:none
    }

    .editsong{
        display:none
    }
  
    div.musicitemhome{
      width: 36%;
      /* height: 50px; */
      /* background: red; */
      /* margin: 20px; */
      /* margin: 20px 20px 30px -15px; */
      float: left;
      }
  
    /* div.musicitemmain{
      width: 20%;
      float: left;
      }
   */
  
  
    div.musicitem2{
      width: 15%;
      /* height: 50px; */
      /* background: red; */
      /* margin: 20px; */
      /* margin: 20px 20px 30px -15px; */
      float: left;
      }
  
      .textfont{
        font-size:14px;
        text-align:center
      }
  
  
      .textfontnewmusic{
        font-size:14px;
        color:#D8D8D8
  
      }
  
/* p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
} */

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background-image: url("/src/webapp/x.png");

    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}
.AnyElementYouWantToShowOnAjaxSend {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; /* to make it responsive */
    width: 100vw; /* to make it responsive */
    overflow: hidden; /*to remove scrollbars */
    z-index: 99999; /*to make it appear on topmost part of the page */
    display: none; /*to make it visible only on fadeIn() function */
        background: #000;
}
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999;

}

.loading-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100px;
    line-height: 100px;

}
.loading-text span {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.loading-text span:nth-child(1) {
    filter: blur(0px);
    animation: blur-text 1.5s 0s infinite linear alternate;
}
.loading-text span:nth-child(2) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.2s infinite linear alternate;
}
.loading-text span:nth-child(3) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.4s infinite linear alternate;
}
.loading-text span:nth-child(4) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.6s infinite linear alternate;
}
.loading-text span:nth-child(5) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.8s infinite linear alternate;
}
.loading-text span:nth-child(6) {
    filter: blur(0px);
    animation: blur-text 1.5s 1s infinite linear alternate;
}
.loading-text span:nth-child(7) {
    filter: blur(0px);
    animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(7) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(8) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(9) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(10) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(11) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(12) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
.loading-text span:nth-child(13) {
  filter: blur(0px);
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}
a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.webappnavbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.webapptopbar{
    padding-top:20px;
    padding-bottom:10px;
}

.webappleftarrow{
    padding-left:30px;
}

.webapprightarrow{
    padding-left:30px;
   /* width:11px;
   height:20px */
}

.webappheaderlogo{
    width:37px;
    height:40px;
  }

  .webapplogotext{
color:#FFF;

}

  .webapphamburger{
      width:21px;
      height:15px;
      cursor:pointer;
  }

  .webappnavlink{
      padding-left:30px;
      padding-bottom:10px;
      padding-top:10px
  }

  .webappgradelink{
    padding-left:30px
  }

  .webappearningslink{
    padding-left:30px;
    padding-bottom:30px
  }

  .webapplinkicon{
      font-size:23px;
      padding-right:15px
  }

  .webappactivelink{
      color:#FA2D48;
      background-color:#1A1A1A
  }

.settingsdp{
    border-radius:50%;
    width:160px;
    height:160px
}

.settingsusertype{
    color:#949494!important;
    padding-top:20px

}

#songart{
    width:250px;
    height:250px
}

.footermenup{
    text-align:center;
    float:left
}

#profilenamesettings{
    color:#FFF;
    font-size:20px
}

#profilenamesettings2{
    color:#FFF;
    font-size:16px;
    padding-top:20px
}
.settingsbutton{
    color:#FFF;
    background-color:#FA2D48;
    border-radius:20px;
    padding:10px 35px 10px 35px;
    border:#FA2D48

}

.avatarbutton{
    color:#FFF;
    background-color:#D90C5F;
    border-radius:20px;
    padding:8px 30px 8px 30px;
    border:#D90C5F;
    font-size:16px

}

.settingspersonalinfo{
    color:#DFDFDF;
    padding-left:30px
}

.settingssecondcontainer{
    margin-top:40px
}

.settingssecondcontainer2{
    margin-top:40px
}

.settingsacc{
    margin-top:10px
}

.pismh{
    padding-top:50px;
    margin-bottom:50px;
    color:#949494;

}

.pismh2{
    padding-top:50px;
    margin-bottom:30px;
    color:#949494;

}

.streamsongtitle{
    text-transform: capitalize;
}

.streamartist{
    text-transform: capitalize
}

.twitterverifybox{
    display:none
}

.footermenup3{
    text-align:center;
    margin-top:0px;
}

.pibutton{
    color:#FFF;
    background-color:#D90C5F;
    padding-top:15px;
    padding-bottom:15px;
    border-radius:30px;
    font-size:17px;
    margin-top:20px
}

.morebutton{
    color:#FFF;
    background-color:#D90C5F;
    border-radius:10px;
    font-size:13px;
    padding-bottom:4px;
    padding-top:5px
    

}

.fundaccountbutton{
    font-size:18px;
    background-color: #FA2D48;
    border-radius: 20px;
    border-color:#FA2D48;
    margin-top:20px;
    padding: 8px 40px 8px 40px;
    margin-bottom:60px;
    color:#FFF
 
  }

  .welcometext{
    color:#FFF;
    padding-top:40px;
    padding-bottom:15px
  }

  .welcometextt{
    color:#FFF;
    padding-top:20px;
    /* padding-bottom:15px */
  }

  

.fundaccountbutton2{
    font-size:14px;
    background-color: #FA2D48;
    border-radius: 20px;
    border-color:#FA2D48;
    margin-top:20px;
    padding: 8px 40px 8px 40px;
    margin-bottom:60px;
    color:#FFF;
    font-weight:bold
 
  }

  .onboardedbutton{
    font-size:14px;
    background-color: #FA2D48;
    border-radius: 20px;
    border-color:#FA2D48;
    /* margin-top:20px; */
    padding: 8px 40px 8px 40px;
 
    color:#FFF;
    font-weight:bold
  }

  .ifthis{
      text-align:center;
      padding-top:20px
  }

  .twitterverifybox{
      padding-top:30px
  }

  .requestpayoutbutton{
    font-size:14px;
    background-color: #D90C5F;
    border-radius: 20px;
    border-color:#FA2D48;
    margin-top:20px;
    padding: 8px 40px 8px 40px;

    color:#FFF;
    font-weight:bold
 
  }

  .fundaccountbuttonp{
    text-align:center;
    display:none
  }


.dashboardbalance{
    background-image: url("/src/webapp/moneybg.png");
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: #121212;
    padding-bottom:30px;
    padding-top:0px;
    margin-top:25px;
    margin-bottom:20px;
    padding-left:40px;
    border-radius:20px

  
  }

  .top100singlehead{
    margin-top:40px
}

  .disablebutton {
                              
    pointer-events: none; /* Disable an element interaction, so it will not respond any event */
}


.dashboardstreams{
    background-image: url("/src/webapp/streamsbg.png");
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: #121212;
    padding-bottom:80px;
    padding-top:0px;
    margin-top:25px;
    margin-bottom:20px;
    padding-left:40px;
    border-radius:20px
  
  }

  .dashboardpagetext{
      color:#949494;
      font-size:20px;
      padding-top:40px

  }

  .image-upload>input {
    display: none;
  }

  .dashboardhelp{
      float:right;
      color:#FFF;
      cursor:pointer;
      font-size:18px
  }

  .fundaccounttexthead{
      text-align:center;
      color:#C4C4C4
  }

  .fundaccounttextbody{
    text-align:center;
    color:#C4C4C4
}

.uploadsonghead{
    color:#C4C4C4;
    padding-top:30px;
    padding-bottom:10px;
    font-size:18px
}

.uploadsonghead2{
    color:#C4C4C4;
    padding-top:0px;
    padding-bottom:10px;
    font-size:18px
}

.uploadasongart{
    margin-bottom:40px
}

::-webkit-file-upload-button {
    background: #1A1A1A;
    border-color: #FA2D48;
    border-radius:5px;
    color: #C4C4C4;
    padding: 1em;
  }

#file2{
    color:#C4C4C4
}

.tabs{

    margin:0 auto;
    width:70%;
   
    /* text-align:center; */
   
  }
  
  /* img{
    width:300px;
  } */
  
  .tab-buttons{
    margin-bottom:50px;
  }
  
  button{
    margin-right:15px;
    font-size:20px;
    background:transparent;
    border:none;
    outline:none;
    padding:10px 20px ;
    cursor:pointer;
    color:#FFF;
    transition:all ease-in-out .2s;
    border-bottom:2px solid transparent;
    
  }
  .tabactive{
    border-bottom:2px solid rgb(255, 255, 255);
   
    color:#FFF;
  }

/* ::-webkit-file-upload-button {
    background: black;
    color: red;
    padding: 1em;
  } */




.form-control:disabled, .form-control[readonly] {
    opacity: 1;
    background-color: #121212;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.webappnavbarwrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#webappsidebar {
    min-width: 270px;
    max-width: 270px;
    background: #0A0A0A;
    color: #C4C4C4;
    transition: all 0.3s;
    height:100vh;
  
    
    
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: fixed; 
}

/* #webappsidebar {
  
    min-width: 270px;
    max-width: 270px;
 
  } */

#webappsidebar.active {
    margin-left: -250px;
}

#webappsidebar .sidebar-header {
    padding: 20px;
    background: #0A0A0A;
}

#webappsidebar ul.components {
    padding: 20px 0;
    /* border-bottom: 1px solid #47748b; */
}

#webappsidebar ul p {
    color: #fff;
    padding: 10px;
}

#webappsidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#webappsidebar ul li a:hover {
    color: #D90C5F;
    /* background: #1A1A1A; */
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.webappdropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

/* #webappcontent {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
  min-width: 100vw;

} */

/* .webappinnercontent{
       padding: 20px;
} */

#profilename{
  
    color: #FFFFFF;
  
  }

  #profilename:hover{
  
    color: #FA2D48;
  
  }

.webappinnerheading{
    color:#DFDFDF;
    margin-top:40px;
    padding-left:15px;
    color:#DFDFDF
}

.viewall{
    font-size:16px
}

.webappinnerheading2{
    color:#DFDFDF;
    margin-top:0px;
    padding-left:15px;
    color:#DFDFDF;
    padding-top:20px
}

.webappinnerheading3{
    color:#DFDFDF;
    margin-top:40px;
    padding-left:15px;
    color:#DFDFDF;
    padding-bottom:30px
}




.row>* {
    flex-shrink: 10;
    /* padding-left:20px */
  
}




  .top100banner{
      border-radius:25px;
      height:250px;
      width:250px
  }

  .nmsartistalbum{
      color:#FFF;
      padding-top:5px;
      font-size:20px
  }

  .nmsartistname{
      color:#949494
  }

  .editorsnotetop100{
    color:#949494
  }

  .searchpageboxes{
      margin-top:20px
  }

  .searchbar{
      margin-right:1300px
  }

  .searchhr{
      border-top: 1px solid #A9A9A9;
  }

  .ippiscol0{
      color:#949494
  }

  .css-serial {
    counter-reset: serial-number; 
   }
   .css-serial td:first-child:before {
    counter-increment: serial-number; 
    content: counter(serial-number); 
   }

   .aotwtable{
       margin-left:40px;
       margin-right:40px
   }
   
   
.dashboardpagefigures{
    color:#FFF
}

.table thead th {
    vertical-align: bottom;
    border: none!important;
}
th{
    font-weight:light!important;
    color:#FFF!important

}

table, tr, td {
    border: none!important;
}

.ippiscol0{
    border: none!important;
}

.ippisschedulehead{
    border: none!important;
}


.modal-body{
    background-color:#1A1A1A;
  }
  
  .modal-footer{
    background-color:#1A1A1A;
    border-top: 0px solid #000
  
  }
  
  .modal-header{
    background-color:#1A1A1A;
    border-bottom: 0px solid #000
  }

  .requestnotify{
    padding-top:20px;
    /* padding-bottom:40px; */
 
    color:#FFF;
    font-weight:bolder
  }
  
  .requestnotify2{
    padding-top:20px;
    text-align:center;
    /* padding-bottom:40px; */
 
    color:#FFF;
    font-weight:light
  }

  .requestnotify3{
    padding-top:20px;
    /* text-align:center; */
    /* padding-bottom:40px; */
 
    color:#FFF;
    font-weight:light
  }

  .popupdiv{
      display:none;
      margin-top:-250px
  }

  .controlbuttons1 {
    color: #fff;
    background-color: #FA2D48;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-top: 6px;
    padding-bottom: 7px;
    border-radius:8px;
    font-size:12px;
}

  .controlbuttons {
    color: #fff;
    background-color: #FA2D48;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-top: 6px;
    padding-bottom: 7px;
    /* margin-left:10px; */
    border-radius:8px;
    border-color:#FA2D48;
    font-size:12px;
    margin-top:10px
}

.artistnotify {
    font-size: 15px;
}

.qdet1 {
    color: #fff!important;
    /* padding-top: 2px; */
}



.alreadyearned{
    color: #fff;;
    display:none
}

.nmsbuttons{
    color: #949494;
    padding-top:20px
}

#musicbox3{margin-left:40px}

.controlbuttons2{
    border-radius:10px;
    padding-left:10px;
     padding-right:10px;
     padding-top:7px;
      padding-bottom:7px;
      font-size:12px;
      /* font-weight:bold; */
      background-color:#FA2D48;
      color:#FFF;
      border:#FFF;
      /* margin-left:10px */
}

.nmsartistyear{
    color:#949494
}

#follownotify{
    color:#FFF
}

  .frjscc {
    min-height: 44px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.frjscc > input {
    width: 100%;
    padding: 0 0 0 13px;
    border: none;
    outline: none;
    background-color: rgba(0,0,0,0);
    font-size: inherit;
    font-family: inherit;
    color: #212121;
   
}

#searchresult{
    display:none
}

/****** Style Star Rating Widget *****/

.rating { 
    border: none;
    /* float: left; */
    
  }
  
  .rating > input { display: none; } 
  .rating > label:before { 
    margin: 5px;
    font-size: 1.25em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
  }
  
  .rating > .half:before { 
    content: "\f089";
    position: absolute;
  }
  
  .rating > label { 
    color: #ddd; 
   float: right; 
  
  }
  
  /***** CSS Magic to Highlight Stars on Hover *****/
  
  .rating > input:checked ~ label, /* show gold star when clicked */
  .rating:not(:checked) > label:hover, /* hover current star */
  .rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */
  
  .rating > input:checked + label:hover, /* hover current star when changing rating */
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
  .rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 


  .searchbox{
    width:260px;
    margin-left:20px;

}

.accountpagehr{
    border-top: 1px solid #FFF;
    margin-top: 30px
}



/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media  screen and (min-width: 1440px) {
    div.musicitemmain{
      width: 20%;
      float: left;
      height:250px
      }
  
}
@media  screen and (max-width: 1366px) {
    #page-content-wrapper {
     
      height:130vh;
      }

      #page-content-wrapper2 {
    
      }

      .musicimagemain{
        height: 140px;
        width: 140px;
      
        background-color: #fff;
        border: none;
        background-position: center;
        background-size: cover;
        /* transition: all 0.2s ease-in-out !important; */
        border-radius: 15px;
      }

      div.musicitemmain{
        width: 30%;
        float: left;
        height:250px
        }
        
      .earningsbox{
          margin-left:25px
      }

      /* .settingssecondcontainer{
        margin-left:-200px
      } */

      .songart1366{
          float:right
      }

      .streamqdet1366{
          margin-top:-2px;
          font-size:13px
      }

      .songtitle{
        font-size:13px;
      }

      .textfontnewmusic{
        float:left;
        font-size:13px;
  
      }

  
}

@media  screen and (max-width: 1440px) {
    #songart{
        width:180px;
        height:180px
    }


/* .musicimagemain{
    height: 80px;
    width: 80px;

  
   
  } */

    .top100banner{
        border-radius:25px;
        height:200px;
        width:200px
    }

    .alreadyearned1024{
        font-size:13px;
        margin-top:-10px
    }

    .nmsartistalbum{
        font-size:17px
    }

    .nmsartistname{
        font-size:17px
    }

    .nmsartistyear1024{
        font-size:12px
    }

    .nmsbuttons1024{
        padding-top:0px
    }

    div.musicitemmain{
        width: 20%;
        float: left;
        height:250px
        }


        
        .albumimage{
            height: 160px;
            width: 160px;
          
            background-color: #fff;
            border: none;
            background-position: center;
            background-size: cover;
            transition: all 0.2s ease-in-out !important;
            border-radius: 12px;
          }

          .songtitle{
            font-size:15px;
          }

    .controlbuttons1 {
        
        padding-top: 6px;
        padding-bottom: 3px;
        border-radius:8px;
        font-size:10px;
        
    }

    .controlbuttons {
        padding-top: 6px;
        padding-bottom: 7px;
        border-radius:8px;
        font-size:10px;
        margin-top:0px
    }

    .controlbuttons2{
        border-radius:10px;
        padding-left:10px;
         padding-right:10px;
         padding-top:7px;
          padding-bottom:7px;
          font-size:10px;
          margin-top:-50px
     
        
    }
   
}
@media  screen and (max-width: 1024px) {

    .musicimagemain{
        height: 140px;
        width: 140px;
      
        background-color: #fff;
        border: none;
        background-position: center;
        background-size: cover;
        /* transition: all 0.2s ease-in-out !important; */
        border-radius: 15px;
      }
    #songart{
        width:180px;
        height:180px
    }

    .alreadyearned1024{
        font-size:13px;
        margin-top:-10px
    }

    .nmsartistalbum{
        font-size:17px
    }

    .nmsartistname{
        font-size:17px
    }

    .nmsartistyear1024{
        font-size:12px
    }

    .nmsbuttons1024{
        padding-top:0px
    }

    div.musicitemmain{
        width: 33%;
        float: left;
        height:250px
        }
        
        .albumimage{
            height: 110px!important;
            width: 110px!important;
          
            background-color: #fff;
            border: none;
            background-position: center;
            background-size: cover;
            transition: all 0.2s ease-in-out !important;
            border-radius: 10px!important;
          }

          .songtitle{
            font-size:10px;
          }

    .controlbuttons1 {
        
        padding-top: 6px;
        padding-bottom: 3px;
        border-radius:8px;
        font-size:10px;
        
    }

    .controlbuttons {
        padding-top: 6px;
        padding-bottom: 7px;
        border-radius:8px;
        font-size:10px;
        margin-top:0px
    }

    .controlbuttons2{
        border-radius:10px;
        padding-left:10px;
         padding-right:10px;
         padding-top:7px;
          padding-bottom:7px;
          font-size:10px;
          margin-top:-50px
     
        
    }
   
}

@media (max-width: 768px) {
    #webappsidebar {
        margin-left: -250px;
    }
    #webappsidebar.active {
        margin-left: 0;
    }
    #webappsidebarCollapse span {
        display: none;
    }
}

@media screen and (max-width: 833px) {
    #musicbox3{margin-left:0px}
    .webapprighticon{
        padding-top:30px
    }

    .playbutton{
        text-align:center;
    }

    .clientpp{
        text-align:center
    }

    .rating { 
        border: none;
        float: left;
        margin-left:90px
        
      }

    .mobilebannerp{
        text-align:center
    }

 
    .top100singlehead{
        margin-top:0px
    }

    .footermenup{
        text-align:center;
        float:left;
        margin-left:14px
    }
    

.enteranamount{
    padding-top:30px
}

    .accountpagehr{
        border-top: 1px solid #FFF;
        margin-top: 0px;
    }
    
    
#profilenamesettings{
    color:#FFF;
    font-size:16px
}

#profilenamesettings2{
    color:#FFF;
    font-size:16px
}

.avatarbutton{
    color:#FFF;
    background-color:#FA2D48;
    border-radius:20px;
    padding:8px 14px; 
    border:#FA2D48;
    font-size:16px

}




.settingsusertype{
    color:#949494!important;
    padding-top:0px

}

    .searchbox{
        width:320px;
        margin-top:30px
    }

    .mobilecentercount{
        text-align:center
    }


.row>* {
    /* flex-shrink: 10; */
    /* padding-left:20px */
  
}

    .webappnavlink{
      font-size:14px
}

  .webappearningslink{

    padding-bottom:10px
  }

  #webappsidebar {
    min-width: 250px;
    max-width: 250px;

}

.webappheaderlogo{
    width:20px;
    height:22px;
  }




#webappsidebar {
    /* min-width: 270px;
    max-width: 270px;
    background: #0A0A0A;
    color: #C4C4C4;
    transition: all 0.3s;
    height:100vh;
  
    
    
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; */
    position: relative; 
}

.webappinnerheading2{
    font-size:16px
}

.settingsdp{
    border-radius:50%;
    width:100px;
    height:100px
}
.dashboardbalance{
    background-image: url("/src/webapp/moneybg.png");
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: #121212;
    padding-bottom:45px;
    padding-top:0px;
    margin-top:25px;
    margin-bottom:20px;
    padding-left:20px;
    margin-right:20px;
    border-radius:8px;
  
  }

  .dashboardstreams{
    background-image: url("/src/webapp/streamsbg.png");
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: #121212;
    padding-bottom:45px;
    padding-top:0px;
    margin-top:25px;
    margin-bottom:20px;
    padding-left:20px;
    margin-right:20px;
    border-radius:8px
  }

  .dashboardpagetext{
    color:#949494;
    font-size:20px;
    padding-top:40px

}

.dashboardpagefigures{
    font-size:20px
}

.dashboardpagefigures{
    font-size:20px
}

.toptable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}



}


