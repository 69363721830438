
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999;
}
.loading-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100px;
    line-height: 100px;
}
.loading-text span {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
    font-family: 'Quattrocento Sans', sans-serif;
}
.loading-text span:nth-child(1) {
    filter: blur(0px);
    animation: blur-text 1.5s 0s infinite linear alternate;
}
.loading-text span:nth-child(2) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.2s infinite linear alternate;
}
.loading-text span:nth-child(3) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.4s infinite linear alternate;
}
.loading-text span:nth-child(4) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.6s infinite linear alternate;
}
.loading-text span:nth-child(5) {
    filter: blur(0px);
    animation: blur-text 1.5s 0.8s infinite linear alternate;
}
.loading-text span:nth-child(6) {
    filter: blur(0px);
    animation: blur-text 1.5s 1s infinite linear alternate;
}
.loading-text span:nth-child(7) {
    filter: blur(0px);
    animation: blur-text 1.5s 1.2s infinite linear alternate;
}
@keyframes blur-text {
    0% {
        filter: blur(0px);
   }
    100% {
        filter: blur(4px);
   }
}


/* body{
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
    height:100vh
} */

.homebody{
    background-color:#1A1B1E;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.desktopdisplay{
    display:show
  }
  
  .mobiledisplay{
    display:none;
  }

label{
    color:#fff;
    /* font-weight:bold */
}

.ajax-loader {
    visibility: hidden;
    background-color: rgba(255,255,255,0.7);
    position: fixed;
    z-index: +100 !important;
  
  
    width: 100%;
    height:100%;
    left: 0px;
      top: 0px;
  }

  .ajax-loader img {
    position: relative;
    top:50%;
    left:50%;
  }

.taste{
    background-image: url("../webapp/signinbgdemo.jpeg");
    background-repeat: no-repeat;
    background-size: cover; 
}

.welcome{
    background-image: url("../webapp/signinbgdemo.jpeg");
    background-repeat: no-repeat;
    background-size: cover; 
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.signin{
    /* background-image: url("/images/collagebg.png");
    background-repeat: no-repeat;
    background-size: cover;  */
}

.firstbox{
    /* float:right; */
    padding-top:30px;
}

.secondbox{
    padding-top:100px;
}

.welcomecta{
    margin-top:80px;
}

.tastesecondbox{
    padding-top:50px;
}

.headingone{
    color:#fff;
    font-weight:bold;
    font-size:70px;
    text-align:center;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;

}

.welcomeheadingone{
    color:#D8D8D8;
    font-size:34px;
    line-height: normal;
}

.tasteheadingone{
    color:#D8D8D8;
    font-size:28px;
    line-height: normal;
}

.welcomedescriptionone{
    color:#D8D8D8;
    padding-top:50px;
    margin-bottom:80px;
    font-size:16px;
}

.allsetdescriptionone{
    color:#D8D8D8;
    padding-top:50px;
    margin-bottom:40px;
    font-size:16px;
}

.caption{
    color:#D8D8D8;
    font-size:15px;
}

.item{
    width:200px;
    text-align:center;
    float:left;
  }

  .musicitem{
    width:200px;
    text-align:center;
    float:left;
  }

.descriptionone{
    color:#fff;
    text-align:center;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;


}

.already{
    color:#fff;
    font-size:12px;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;

}

.firstbutton{
    color:#fff!important;
    font-size:12px;
    font-family: Avenir Next,Avenir,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    border-color: #FF0039;
}

.firstbutton:hover{
    background-color:transparent!important;
}

.form-control{
    /* background-color:#18191D!important;
    color:#D8D8D8!important;
    border:1px solid #18191D; */
}

.btn-outline-danger {
    border-color: #FF0039;
}

#notification{
    color:#FF0039;
    font-weight:bold;
}

.continuebutton{
    background-color:#FF0039;
    /* font-weight:bold */
}

.nameinput{
    width:350px
}

.buttoninput{
    width:150px!important;
}

.signupform{
    padding-top:10px;
}

.skipbutton{
    background-color:#18191D;
}

.skipbutton:hover{
    background-color:transparent!important;
}

.tastebutton{
    background-color:#18191D;
margin-left:10px;
margin-top:15px;
}

.continutastebutton{
    margin-top:250px;
    float:right;
}

.item:hover {
    animation: shake 3.5s;
    animation-iteration-count: infinite;
    cursor:pointer;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }


  @media screen and (max-width: 833px) {  

    .desktopdisplay{
        display:none
      }
      
      .mobiledisplay{
        display:block;
      }
    .secondbox{
        padding-top:150px;
    }
    /* .signin{
        background-image: url(/images/collagebg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height:700px!important;
    } */


.welcome{
    background-image: url("../webapp/signinbgdemo.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:700px!important;

}

.taste{
    background-image: url("../webapp/signinmob.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:700px!important;

}

    .headingone{
       font-size:30px;
    }

    .secondbox{
        padding-top:60px;
    }

    .nameinput{
        width:300px
    }

    .firstbox{
        text-align:center
    }
}

